<template>
  <div class="login">
    <div class="login-left">
      <div class="image"></div>
    </div>
    <div class="login-wrap" v-show="type === 'login'">
      <el-form
        label-position="left"
        hide-required-asterisk
        :model="loginForm"
        :rules="loginRules"
        ref="loginForm"
        class="demo-loginForm login-container"
      >
        <div class="loginStyle">
          <div class="loginStyle-top">
            <div class="loginStyle-top-logo"></div>
            <div class="loginStyle-top-soild"></div>
            <div class="loginStyle-top-title">法务管理系统</div>
          </div>
          <div class="loginStyle-bottom">欢迎登录</div>
        </div>
        <el-form-item prop="phone" label="手机号" style="margin-top: 24px">
          <el-input
            type="text"
            v-model="loginForm.phone"
            auto-complete="off"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="verifyCode" label="验证码">
          <el-input
            @keyup.enter.native="() => submitForm('loginForm')"
            type="text"
            v-model="loginForm.verifyCode"
            auto-complete="off"
            placeholder="请输入验证码"
          >
            <i
              slot="suffix"
              style="font-size: 14px; color: #0062ff; margin-right: 15px"
              @click="getcode('postLoginSmsCode')"
              >{{ secondrender }}</i
            >
          </el-input>
        </el-form-item>

        <el-form-item style="width: 100%">
          <el-button
            type="primary"
            style="width: 100%"
            @click="submitForm('loginForm')"
            :loading="logining"
            >登录</el-button
          >
        </el-form-item>
        <div class="btn" style="justify-content: space-between">
          <el-button type="text" size="mini" @click="type = 'passwordlogin'"
            >密码登录</el-button
          >
          <el-button type="text" size="mini" @click="type = 'register'"
            >没有账号？点击注册</el-button
          >
        </div>
      </el-form>
    </div>

    <div class="login-wrap" v-show="type === 'passwordlogin'">
      <el-form
        label-position="left"
        hide-required-asterisk
        :model="passwordloginForm"
        :rules="passwordloginRules"
        ref="passowrdloginForm"
        class="demo-loginForm login-container"
      >
        <div class="loginStyle">
          <div class="loginStyle-top">
            <div class="loginStyle-top-logo"></div>
            <div class="loginStyle-top-soild"></div>
            <div class="loginStyle-top-title">法务管理系统</div>
          </div>
          <div class="loginStyle-bottom">欢迎登录</div>
        </div>
        <el-form-item prop="phone" label="手机号" style="margin-top: 24px">
          <el-input
            type="text"
            v-model="passwordloginForm.phone"
            auto-complete="off"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="verifyCode" label="密码">
          <el-input
            @keyup.enter.native="() => submitForm('loginForm')"
            type="password"
            v-model="passwordloginForm.password"
            auto-complete="off"
            placeholder="请输入密码"
          >
          </el-input>
        </el-form-item>

        <el-form-item style="width: 100%">
          <el-button
            type="primary"
            style="width: 100%"
            @click="submitForm('passowrdloginForm')"
            :loading="logining"
            >登录</el-button
          >
        </el-form-item>
        <div class="btn" style="justify-content: space-between">
          <el-button type="text" size="mini" @click="type = 'login'"
            >验证码登录</el-button
          >
          <el-button type="text" size="mini" @click="type = 'register'"
            >没有账号？点击注册</el-button
          >
        </div>
      </el-form>
    </div>

    <div class="login-wrap" v-show="type === 'register'">
      <el-form
        label-position="left"
        hide-required-asterisk
        :model="registerForm"
        :rules="registerRules"
        ref="registerForm"
        class="demo-loginForm login-container"
      >
        <p class="des">欢迎使用</p>
        <h3 class="title">真好签法务管理系统</h3>
        <el-form-item prop="phone" label="手机号">
          <el-input
            type="text"
            v-model="registerForm.phone"
            auto-complete="off"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>

        <el-form-item prop="verifyCode" label="验证码">
          <el-input
            type="text"
            v-model="registerForm.verifyCode"
            auto-complete="off"
            placeholder="请输入验证码"
          >
            <i
              slot="suffix"
              style="font-size: 14px; color: #0062ff; margin-right: 15px"
              @click="getcode('postRegisterSmsCode')"
              >{{ secondrender }}</i
            >
          </el-input>
        </el-form-item>
        <!-- <el-form-item prop="inviteCode" label="邀请码">
          <el-input type="text" v-model="registerForm.inviteCode" auto-complete="off" placeholder="请输入邀请码"></el-input>
        </el-form-item> -->
        <el-form-item style="width: 100%">
          <el-button
            type="primary"
            style="width: 100%"
            @click="submitForm('registerForm')"
            :loading="logining"
            >注册并登录</el-button
          >
        </el-form-item>
        <div class="btn">
          <el-button type="text" size="mini" @click="type = 'login'"
            >已有账号？点击登录</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>
<script scoped>
export default {
  name: "Login",
  data() {
    const phoneValidator = (_, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      }
      if (this.$utils.test.mobile(value)) {
        return callback();
      } else {
        return callback(new Error("请输入正确的手机号"));
      }
    };
    return {
      type: "login",

      logining: false,
      second: 0,
      loginForm: {
        phone: "",
        verifyCode: "",
        type: 1,
      },

      //loginRules前端验证
      loginRules: {
        phone: [{ validator: phoneValidator, trigger: "blur" }],
        verifyCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 4, max: 4, message: "请输入正确的验证码", trigger: "blur" },
        ],
      },
      passwordloginForm: {
        phone: "",
        password: "",
        type: 2,
      },
      //loginRules前端验证
      passwordloginRules: {
        phone: [{ validator: phoneValidator, trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      registerForm: {
        phone: "",
        verifyCode: "",
        // inviteCode: ''
      },
      registerRules: {
        phone: [{ validator: phoneValidator, trigger: "blur" }],
        verifyCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 4, max: 4, message: "请输入正确的验证码", trigger: "blur" },
        ],
        // inviteCode: [
        //   { required: true, message: '请输入邀请码', trigger: 'blur' },
        //   { min: 6, max: 6, message: '请输入正确的邀请码', trigger: 'blur' }
        // ]
      },
    };
  },
  mounted() {},
  computed: {
    secondrender() {
      return this.second == 0 ? "获取" : this.second + "s";
    },
  },
  methods: {
    secondrun() {
      let js = setInterval(() => {
        this.second--;
        if (this.second == 0) {
          clearInterval(js);
        }
      }, 1000);
    },
    async getcode(res) {
      if (this.second !== 0) {
        return;
      }
      const { status, info } = await this.$api[res]({
        phone: this.type === "login" ? this.loginForm.phone : this.registerForm.phone,
      });
      if (status == 200) {
        this.$message({
          message: info,
          type: "success",
        });
        this.second = 60;
        this.secondrun();
      } else {
        this.$message({
          message: info,
          type: "error",
        });
      }
    },

    //提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.type === "login") {
            this.postUserLogin(this.loginForm);
          } else if (this.type === "register") {
            this.postUserRegister();
          } else if (this.type === "passwordlogin") {
            this.postUserLogin(this.passwordloginForm);
          }
        } else {
          return false;
        }
      });
    },
    setUserInfo(data) {
      this.$store.dispatch("user/setUserInfo", data);
      if (this.$route.query.redirect) {
        let redirect = this.$route.query.redirect;
        this.$router.push(redirect);
      } else {
        this.$router.push("/");
      }
    },
    async postUserLogin(primary) {
      const { info, status, data } = await this.$api.postUserLogin(primary);
      if (status === 200) {
        this.$message({
          message: info,
          type: "success",
        });
        this.setUserInfo(data);
      } else {
        this.$message({
          message: info,
          type: "error",
        });
      }
    },
    async postUserRegister() {
      const { info, status, data } = await this.$api.postUserRegister(this.registerForm);
      if (status === 200) {
        this.$message({
          message: info,
          type: "success",
        });
        this.setUserInfo(data);
      } else {
        this.$message({
          message: info,
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* 当页面宽度小于300px*/
@media screen and (max-width: 1000px) {
  .login-left {
    display: none !important;
  }
}
.login {
  display: flex;
  height: 100vh;
  background-image: url(~@/static/image/login_bg_3.png);
}
.login-left {
  height: 100vh;
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
  background-image: url(~@/static/image/login_bg_4.png);
  background-repeat: no-repeat;
  background-position: center 173px;
  background-size: 80% auto;
}
.login-wrap {
  box-sizing: border-box;
  width: 50%;
  height: 100vh;
  display: flex;
  align-items: center;
  background-size: 429px 482px;
  background-repeat: no-repeat;
  background-position: top right;
  justify-content: center;
  flex: 1;
}
.login-container {
  background: #ffffff;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  padding: 30px;
  width: 440px;
}
.title {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.des {
  font-size: 22px;
  font-weight: 500;
  color: #262626;
  line-height: 30px;
}
.btn {
  display: flex;
  justify-content: flex-end;
  &/deep/.el-button {
    color: #999999;
    &:hover {
      color: #0062ff;
    }
  }
}
.loginStyle {
  &-top {
    display: flex;
    align-items: center;
    &-logo {
      width: 130px;
      height: 50px;
      background-image: url(~@/static/logo1.png);
      background-size: 130px 50px;
      background-repeat: no-repeat;
    }
    &-soild {
      width: 1px;
      height: 16px;
      background: #cccccc;
      margin: 0 20px;
    }
    &-title {
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;

      color: #262626;
    }
  }
  &-bottom {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;

    color: #262626;
    margin-top: 30px;
  }
}
</style>
