export default {
    components: {},
    data() {
        return {
            second: 0,
        };
    },
    created() { },
    computed: {
        secondrender() {
            return this.second == 0 ? '获取' : this.second + 's';
        }
    },
    methods: {
        secondrun(second=5) {
            this.second = second;
            let js = setInterval(() => {
              this.second--;
              if (this.second == 0) {
                clearInterval(js);
              }
            }, 1000);
          },
    },
};